
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "PaymentTypeLabel",
  props: {
    type: {type:String,required: true}
  },
  setup(props) {
    const label = ref('');
    const buildLabel = (code: string) => {
      switch (code) {
        default:
          label.value = 'Service Payment'
      }
    }
    buildLabel(props.type);
    watch(() => props.type, (cb) => {
      buildLabel(cb);
    })
    return {
      label,
    }
  }

})
