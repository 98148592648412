
import {computed, defineComponent, ref} from "vue";
import Entities from "../../components/base/common/Entities.vue";
import ActionLogCards from "./ActionLogCards.vue";
import store from "../../store";
import {CompanySelect, LoadFilterObjects, UserSelect} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "UserActionLog",
  components: {ActionLogCards, Entities},
  props: {
    userId: {type: String}
  },
  setup(props) {
    const filterObject = ref({userId: props.userId})
    const page = computed(() => store.state.ActionViewModule.page);
    return {
      ...CompanySelect(),
      ...UserSelect(),
      filterObject,
      page,
      ...LoadFilterObjects(Actions.LOAD_ACTION_VIEWS_PANEL, filterObject.value, ['user'])
    }
  },
  watch: {
    userId(newVl) {
      this.filterObject.userId = newVl
      this.updateFilterObject(this.filterObject)
    }
  }
})
