
import {defineComponent, ref, watch} from 'vue'
import FormService from "@/core/services/FormService";
import {useToast} from "vue-toast-notification";

export default defineComponent({
  name: "FormSecureLink",
  components: {},
  props: {
    id: {required: true}
  },
  setup(props) {
    const link = ref('')
    const load = (id) => {
      if (id) {
        FormService.secureLink(id).then(res => {
          link.value = res.link
        })
      } else {
        link.value = ''
      }
    }
    load(props.id)
    watch(() => props.id, cb => {
      load(cb);
    })
    return {
      link
    }
  },
  methods:{
    copyMe(){
      navigator.clipboard.writeText(this.link);
      useToast().success('Link Copied')
    }
  }
})
