
import {computed, defineComponent, onBeforeMount, ref, watch} from "vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import CookieService from "@/core/services/CookieService";
import QuickMenu from "@/components/common/QuickMenu.vue";

export default defineComponent({
  name: "kt-aside-primary",
  components: {
    QuickMenu,
    KTUserMenu,
  },
  setup() {
    const store = useStore();
    const href = ref('policy_menu');
    const nav = CookieService.get('NAV');
    if (nav) {
      href.value = nav;
    }

    onBeforeMount(async () => {
      if (store.getters.isUserAuthenticated) {
        await store.dispatch(Actions.LOAD_PROFILE);
      }
    })
    const loadReportMenu = () => {
      store.dispatch(Actions.LOAD_QUICKSIGHTS_MENU);
    }
    const unread = computed(()=>store.state.ChatModule.unread);
    const user = computed(() => store.getters.currentUser)
    const theme = computed(() => store.state.UserModule.theme)
    const state = ref({
      styles: 'background-color: ' + theme.value,
    });
    watch(() => theme.value, (cb) => {
      state.value.styles = ';background-color: ' + cb;
    })
    loadReportMenu();
    const setCookie = (href) => {
      CookieService.save('NAV', href);
    }
    return {
      href,
      user,
      state,
      loadReportMenu,
      setCookie,
      unread,
    }
  }
});
