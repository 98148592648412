
import { defineComponent, onMounted, ref, watch } from "vue";
import store from "../../store";
import Notes from "@/views/note/Notes.vue";
import ActivityDocument from "@/views/activity/ActivityDocument.vue";
import Audits from "@/views/audit/Audits.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import ActivityService from "@/core/services/ActivityService";

export default defineComponent({
  name: "ActivityPanel",
  components: {DateTimeFormat, Audits, ActivityDocument, Notes},
  props: {
    id: {type: String}
  },
  setup(props) {
    const data = ref<any>({})
    const loadData = async (id) => {
      return await ActivityService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb);
      store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data,
    }
  }
})
