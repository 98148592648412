import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-md-12" }
const _hoisted_2 = { class: "input-group" }
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        type: "text",
        class: "form-control",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copyMe && _ctx.copyMe(...args))),
        placeholder: "link",
        value: _ctx.link,
        disabled: ""
      }, null, 8, _hoisted_3),
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.copyMe && _ctx.copyMe(...args)))
      }, "Copy")
    ])
  ]))
}