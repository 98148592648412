
import {defineComponent, ref, watch} from 'vue'
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import {TeamSelect, UserSelectGroup} from "@/core/composite/composite";
import {Field} from "vee-validate";
import ReminderService from "@/core/services/ReminderService";
import Froala from "@/components/base/editor/Froala.vue";

export default defineComponent({
  name: "ReminderForm",
  components: {Froala,  UserListSelect,  BaseForm, DateTimeSelect, FormErrorMessage, Field},
  props: {
    id: {},
    reminder: {type: Object},
  },
  emits: ['saved'],
  setup(props) {
    const submitting = ref(false);
    const model = ref<any>(props.reminder);
    watch(() => props.reminder, cb => {
      model.value = cb;
    })
    return {
      model,
      submitting,
      ...UserSelectGroup(),
      ...TeamSelect()
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      if (this.id) {
        ReminderService.update(this.id, this.model).then(res => {
          this.$emit('saved', res)
        }).finally(() => {
          this.submitting = false
        })
      } else {
        ReminderService.create(this.model).then(res => {
          this.$emit('saved', res)
        }).finally(() => {
          this.submitting = false
        })
      }
    }
  }
})
