
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "BenefitType",
  props: {
    type: {type: String,}
  },
  setup(props) {
    const data = ref('')
    const build = (status) => {
      switch (status) {
        case 'M':
          data.value = 'Medical'
          break
        case 'EH':
          data.value = 'Extended health'
          break
        case 'ERH':
          data.value = 'Emergency return home'
          break
        case 'TI':
          data.value = 'Trip interruption'
          break
        case 'TC':
          data.value = 'Trip Cancellation'
          break
      }
    }
    build(props.type)
    watch(() => props.type, (cb) => {
      build(cb)
    })
    return {
      data
    }
  }
})
