
import {computed, defineComponent, ref} from "vue";
import {onUpdated} from "@vue/runtime-core";
import store from "@/store";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import ActionLogCards from "@/views/actionView/ActionLogCards.vue";

export default defineComponent({
  name: "ActionViewPanel",
  components: {ActionLogCards},
  props: {
    id: {type: Object}
  },
  setup(props) {
    const filterObject = ref({objectType: props.id?.type, objectId: props.id?.id, panel :true})
    onUpdated(() => {
      store.commit('SET_PANEL_LOADING', false)
    })
    const page = computed(() => store.state.ActionViewModule.page);
    return {
      ...LoadFilterObjects(Actions.LOAD_ACTION_VIEWS, filterObject.value, ['user']),
      page
    }
  }
})
