<script >
import {defineComponent, onMounted, ref, watch} from 'vue'
import store from "@/store";
import SanctionCheckService from "@/core/services/SanctionCheckService";
import Row from "@/components/base/common/Row.vue";
import "vue3-json-viewer/dist/index.css";
import SanctionDecisionStatus from "@/views/sanction-check/SanctionDecisionStatus.vue";
export default defineComponent({
  name: "SanctionCheckPanel",
  components: {SanctionDecisionStatus, Row},
  props:{
    id: {}
  },
  setup(props) {
    const data = ref({})
    const load = async (id) => {
      return await SanctionCheckService.get(id);
    }
    onMounted(async () => {
      data.value = await load(props.id)
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await load(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data,
    }
  }
})
</script>

<template>
  <div v-if="data.id">
    <div class="card">
      <div class="card-header">
        <div class="card-title">{{data.name}} &nbsp; <SanctionDecisionStatus :decision="data.decision" />
        </div>
      </div>
      <div class="card-body">
        <Row
          :row="[{name: 'ID', value : data.sanctionId},{name:'Entity Type' ,value: data.entityType}]">

        </Row>
        <Row
          :row="[{name:'Country' ,value: data.country} , {name: 'Year Of Birth', value: data.yearOfBirth}]">
        </Row>
        <template v-if="data.param">
          <Row
            :row="[{name:'Min Score' ,value: data.param.minScore}, {name:'Data Source' ,value: data.param.dataSource.join(',')}]">
          </Row>
        </template>
        <template v-if="data.decision">
          <Row :row="[{name: 'Decision By', slot:true, key:'decision'}, {name: 'Decision Date', value: data.decisionDt, type:'DATE'}]">
              <template v-slot:decision>
                {{data?.decisionBy?.fullName}}
              </template>
          </Row>
          <div class="row">
            <div class="d-flex flex-wrap fw-bold">
              <div class="flex-grow-1  text-gray-400">
                Decision Comment:
              </div>
            </div>
            <div class="row">
              <div v-html="data?.decisionComment"></div>
            </div>
          </div>
        </template>
      </div>
      <div class="card-header">
        <div class="card-title"> Results</div>
      </div>
      <div class="card-footer">
        <json-viewer
          :value="data.results?.results"
          :expand-depth=5
          copyable
          boxed
          ></json-viewer>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>