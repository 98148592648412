import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CaseStatus = _resolveComponent("CaseStatus")!
  const _component_ClaimDecisionStatus = _resolveComponent("ClaimDecisionStatus")!
  const _component_ClaimDocStatus = _resolveComponent("ClaimDocStatus")!

  return (_ctx.claim.status ==='CLOSED' || _ctx.claim?.status?.code ==='CLOSED' || _ctx.claim.claimStatus ==='CLOSED' || _ctx.claim.claimStatus ==='Closed')
    ? (_openBlock(), _createBlock(_component_CaseStatus, {
        key: 0,
        status: _ctx.claim.claimStatus ? _ctx.claim.claimStatus : _ctx.claim.status
      }, null, 8, ["status"]))
    : (_ctx.claim.decisionStatus==='PAYABLE'|| _ctx.claim.decisionStatus==='DENIED')
      ? (_openBlock(), _createBlock(_component_ClaimDecisionStatus, {
          key: 1,
          status: _ctx.claim.decisionStatus
        }, null, 8, ["status"]))
      : (_ctx.claim.docStatus ==='PENDING' && _ctx.claim.status !=='PENDING')
        ? (_openBlock(), _createBlock(_component_ClaimDocStatus, {
            key: 2,
            status: _ctx.claim.docStatus,
            prefix: `documents`
          }, null, 8, ["status"]))
        : (_openBlock(), _createBlock(_component_CaseStatus, {
            key: 3,
            status: _ctx.claim.claimStatus ? _ctx.claim.claimStatus: _ctx.claim.status
          }, null, 8, ["status"]))
}