
import {defineComponent, onMounted, ref, watch} from "vue";
import Row from "@/components/base/common/Row.vue";
import ClaimService from "@/core/services/ClaimService";
import store from "@/store";
import PolicyStatus from "@/views/policy/PolicyStatus.vue";
import PolicyService from "@/core/services/PolicyService";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import PolicyData from "@/views/policy/PolicyData.vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import Timelines from "@/views/timeline/Timelines.vue";
import InsuredData from "@/views/insured/InsuredData.vue";
import ClaimStatusMsg from "@/views/claim/ClaimStatusMsg.vue";
import TagEntities from "@/views/tag/TagEntities.vue";
import ClaimDocStatus from "@/views/claim/adjudication/ClaimDocStatus.vue";
import ClaimDecisionStatus from "@/views/claim/adjudication/ClaimDecisionStatus.vue";


export default defineComponent({
  name: "ClaimPanel",
  components: {
    ClaimDecisionStatus,
    ClaimDocStatus,
    TagEntities,
    ClaimStatusMsg,
    InsuredData, Timelines, Avatar, CurrencyFormat, PolicyData, DateTimeFormat, PolicyStatus, Row
  },
  props: {
    id: {type: String}
  },
  setup(props) {
    const data = ref<any>({})
    const policyData = ref<any>({});
    const loadPolicyData = async (id) => {
      return await PolicyService.get(id);
    }
    // const loadInsuredData
    const loadData = async (id) => {
      return await ClaimService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      policyData.value = await loadPolicyData(data.value.policy.id);
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      policyData.value = await loadPolicyData(data.value.policy.id);
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data,
      policyData,
    }
  }
})
