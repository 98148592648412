
import { defineComponent } from "vue";

export default defineComponent({
name: "MenuItem",
props: {
  item: {required: true, type: Object},
},
emits: ['click'],
setup(_, {emit}) {
  const clickItem = (item) => {
    emit('click', item)
  }
  return {
    clickItem
  }
}
})
