import {BaseService} from "@/core/services/BaseService";
import {generateString} from "@/core/helpers/functions";

class InsuredIService extends BaseService<any> {
  endpoint = "/insured"

  async searchInsured(data) {
    const filter = generateString(data);
    return this.http().get(this.endpoint + "/search?" + filter).then(res => {
      return res.data;
    })

  }

  async getInsuredData(id) {
    return this.http().get(this.endpoint + "/" + id +"/data").then(res=>{
      return res.data
    })
  }
}

export default new InsuredIService();