
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "ClaimDecisionStatus",
  props: {
    status: {},
  },
  setup(props) {
    const data = ref({clazz: '', name: ''})
    const buildStatus = (status) => {
      switch (status) {
        case 'NO_DECISION':
          data.value = {clazz: 'dark', name: 'No Decision'}
          break
        case 'PAYABLE':
          data.value = {clazz: 'success', name: 'Payable'}
          break
        case 'DENIED':
          data.value = {clazz: 'danger', name: 'Denied'}
          break
      }
    }
    watch(() => props.status, (cb) => {
      buildStatus(cb)
    })
    buildStatus(props.status)
    return {
      data
    }
  }
})
