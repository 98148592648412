<template>
  <div class="card" v-if="data">
    <div class="card-header">
      <h3 class="card-title">
        Activities Summary
      </h3>
      <div class="card-toolbar">
        <button type="button" class="btn btn-sm btn-primary" @click="exportToPDF">Download PDF</button>
      </div>
    </div>
    <div class="card-body pt-5 pb-3" id="tr_activity_summary">
      <div class="timeline" v-if="data.total > 0">
        <!--begin::Timeline item-->
        <div class="timeline-item" v-for="(activity, i) in data.data" v-bind:key="i">
          <!--begin::Timeline line-->
          <div class="timeline-line w-40px"></div>
          <!--end::Timeline line-->
          <!--begin::Timeline icon-->
          <div class="timeline-icon symbol symbol-circle symbol-40px">
            <div class="symbol-label bg-light">
              <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
              <span class="svg-icon svg-icon-2 svg-icon-gray-500">
																<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none">
																	<path opacity="0.3"
                                        d="M2 4V16C2 16.6 2.4 17 3 17H13L16.6 20.6C17.1 21.1 18 20.8 18 20V17H21C21.6 17 22 16.6 22 16V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4Z"
                                        fill="black"></path>
																	<path
                                    d="M18 9H6C5.4 9 5 8.6 5 8C5 7.4 5.4 7 6 7H18C18.6 7 19 7.4 19 8C19 8.6 18.6 9 18 9ZM16 12C16 11.4 15.6 11 15 11H6C5.4 11 5 11.4 5 12C5 12.6 5.4 13 6 13H15C15.6 13 16 12.6 16 12Z"
                                    fill="black"></path>
																</svg>
															</span>
              <!--end::Svg Icon-->
            </div>
          </div>
          <!--end::Timeline icon-->
          <!--begin::Timeline content-->
          <div class="timeline-content mb-10 mt-n1">
            <!--begin::Timeline heading-->
            <div class="pe-3 mb-5">
              <!--begin::Title-->
              <div class="fs-5 fw-bold mb-2">
                <template v-if="activity.title">
                  {{activity.title}}
                </template>
                <template v-else>
                  {{activity.description}}
                </template>
              </div>
            </div>
            <div class="overflow-auto">
              <div class=" align-items-center border border-dashed border-gray-300 rounded p-3" v-if="activity.note"
                   v-html="activity.note">
              </div>
            </div>
            <div class="d-flex align-items-center mt-1 fs-6">
              <!--begin::Info-->
              <div class="text-muted me-2 fs-7">
                <DateTimeFormat :date="activity.dateCreated" :clazz="`fw-bolder`" :time="true"/>
                by
                <span class="fw-bolder">{{activity?.user?.fullName}}</span>
              </div>
              <!--end::Info-->
              <!--begin::User-->

              <!--end::User-->
            </div>

            <!--end::Description-->
          </div>
          <!--end::Timeline content-->
        </div>
        <!--end::Timeline item-->
      </div>
    </div>
  </div>

</template>

<script>
import html2pdf from "html2pdf.js";
import {defineComponent, onMounted, ref, watch} from "vue";
import ActivityService from "@/core/services/ActivityService";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import store from "@/store";

export default defineComponent({
  name: "CasePreview",
  components: { DateTimeFormat},
  props: {
    id: {type: Object}
  },
  setup(props) {
    const refId = ref(props.id)
    const data = ref({});
    const filter = ref  ({
      paginationPage: 1,
      paginationSize: 500,
      key: {ep: 'user'},
    })
    switch (refId?.value?.type) {
      case 'CASE':
        filter.value.key.cazeId = refId.value.id
        break
      case 'CLAIM':
        filter.value.key.claimId = refId.value.id
        break
      case 'INVOICE':
        filter.value.key.invoiceId = refId.value.id
        break
      case 'POLICY' :
        filter.value.key.policyId = refId.value.id
        break
      case 'PROCESS' :
        filter.value.key.processId = refId.value.id
        break
    }
    const load = async () => {
      return await ActivityService.findAll (filter.value);
    }
    onMounted(async () => {
      data.value = await load();
      store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async () => {
      data.value = await load();
      store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data
    }
  },
  methods: {
    exportToPDF() {
      html2pdf(document.getElementById('tr_activity_summary'),
        {
          margin: 1,
          filename: "activities.pdf",
        }
      );
    },
  }
})
</script>

