
import {defineComponent, onMounted, ref, watch} from "vue";
import store from "@/store";
import CheckListService from "@/core/services/CheckListService";
import Row from "@/components/base/common/Row.vue";
import {Actions} from "@/store/enums/StoreEnums";
import Timelines from "@/views/timeline/Timelines.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import DocPage from "@/views/actionTemplate/DocPage.vue";

export default defineComponent({
  name: "CheckListPanel",
  components: {DocPage, DateTimeFormat, Timelines, Row},
  props: {
    id: {type: String}
  },
  setup(props) {
    const refId = ref(props.id);
    const data = ref<any>({});
    const loadData = async (id) => {
      return await CheckListService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(refId.value);
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data,
      loadData,
    }
  },
  methods: {
    updateNoRequire() {
      CheckListService.updateStatus(this.data.id, 'NO_REQUIRE').then(async () => {
        const time = this.$refs.checkListTimeline as typeof Timelines
        time.paginationLoad()
        const id = this.data.area === 'CASE' ? this.data.caze : this.data.claim
        await store.dispatch(Actions.LOAD_CHECK_LISTS, {type: this.data.area, id: id, area: this.data.area});
      }).catch(() => {
      }).finally(async () => {
        this.data = await this.loadData(this.id)
      })
    },
    onCompleteItem(check) {
      const status = !check.status || check.status === 'DONE' ? 'NO_REQUIRE' : 'DONE';
      CheckListService.updateStatusItem(check.id, status).then(async () => {
        const time = this.$refs.checkListTimeline as typeof Timelines
        time.paginationLoad()
        const id = this.data.area === 'CASE' ? this.data.caze : this.data.claim
        await store.dispatch(Actions.LOAD_CHECK_LISTS, {type: this.data.area, id: id, area: this.data.area});
      }).catch(() => {
      }).finally(async () => {
        this.data = await this.loadData(this.id)
      })
    },
    onComplete(data) {
      CheckListService.updateStatus(data.id, 'DONE').then(async () => {
        const id = this.data.area === 'CASE' ? this.data.caze : this.data.claim
        await store.dispatch(Actions.LOAD_CHECK_LISTS, {type: this.data.area, id: id, area: this.data.area});
        const time = this.$refs.checkListTimeline as typeof Timelines
        time.paginationLoad()
        this.data = await this.loadData(this.id);
      })
    },
    onChange(event, check) {
      const status = !check.status || check.status === 'DONE' ? 'NO_REQUIRE' : 'DONE';
      CheckListService.updateStatusItem(check.id, status).then(res => {
        event.target.checked = res.status == 'DONE';
        const time = this.$refs.checkListTimeline as typeof Timelines
        time.paginationLoad()
      }).catch(() => {
        event.target.checked = false;
      }).finally(async () => {
        this.data = await this.loadData(this.id)
      })
    },
  }
})
