
import {defineComponent, ref} from 'vue'
import {Mutations} from "@/store/enums/StoreEnums";
import store from "@/store";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Swal from "sweetalert2";
import TagService from "@/core/services/TagService";
import TagEntityService from "@/core/services/TagEntityService";
import {TAG_COLOR} from "@/core/config/Constant";

export default defineComponent({
  name: "Tags",
  components: {QuickAction},
  props: {
    entity: {type: Object, required: true},
  },
  setup(props) {
    const data = ref<any>([])
    const loadData = (entity) => {
      TagService.loadTags(entity?.objectType, entity?.processType ? entity.processType : 'MEDICAL_REVIEW').then(res => {
        data.value = res;
      })
    }
    loadData(props.entity);
    const headers = [
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]
    const state = ref({tagForm: 'Add Tag', id: '', tag: {name: '', color: 'green', objectType: ''}})

    return {
      headers,
      state,
      data,
    }

  },

  methods: {
    buildColor(color) {
      const c = TAG_COLOR.find(item => item.name === color);
      return c ? c.hex : '';
    },
    onAddToEntity(tagId) {
      Swal.showLoading();
      TagEntityService.addToEntity(tagId, this.entity.objectId, this.entity.objectType).finally(() => {
        Swal.close()
        store.commit(Mutations.ADDED_TAGS)
      });
    }
  }
})
