
import { defineComponent, ref, watch } from "vue";
import LookupService from "@/core/services/LookupService";

export default defineComponent({
  name: "Lookup",
  props: {
    code: {type: String, required: true},
    type: {type: String, required: true}
  },
  setup(props) {
    const desc = ref('')
    const isValid = ref(true);

    const check = (code, type) => {
      if (code) {
        LookupService.checkCode(type, code).then(res => {
          if (res.status === false) {
            isValid.value = false;
            desc.value = '';
          } else {
            isValid.value = true;
            desc.value = res.desc
          }
        })
      } else {
        desc.value = ''
      }
    }
    check(props.code, props.type);
    watch(() => props.code, (cb) => {
      check(cb, props.type)
    })
    watch(() => props.type, (cb) => {
      check(props.code, cb)
    })
    return {
      desc,
      isValid,
    }
  }
})
