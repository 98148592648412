
import {computed, defineComponent, ref, watch} from "vue";
import * as Yup from "yup";
import BaseForm from "@/components/base/form/BaseForm.vue";
import Froala from "@/components/base/editor/Froala.vue";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {TeamSelect, UserSelectGroup} from "@/core/composite/composite";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import TaskService from "@/core/services/TaskService";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {Field} from 'vee-validate';
import UserListSelect from "@/components/common/UserListSelect.vue";
import TemplateListSelect from "@/components/common/TemplateListSelect.vue";
import store from "@/store";

export default defineComponent({
  name: "FormTask",
  components: {
    TemplateListSelect,
    UserListSelect,
    FormErrorMessage, BaseSelect, BaseRadio, DateTimeSelect, Froala, BaseForm, Field
  },
  props: {
    id: {},
    task: {},
    selectClient: {required: false, default: false}
  },
  emits: ['saved'],
  setup(props) {
    const submitting = ref(false)
    const model = ref<any>(props.task)
    watch(() => props.task, cb => {
      model.value = cb
    })
    const validate = Yup.object().shape({
      name: Yup.string().required().label("Name"),
    });
    const clientId = computed(() => store.state.TemplateModule.clientId);
    return {
      clientId,
      validate,
      model,
      submitting,
      ...UserSelectGroup(),
      ...TeamSelect()
    }
  },
  methods: {
    onSelect(item) {
      this.model.description = item.body
      this.model.name  = item.subject
    },

    onSubmit() {
      this.submitting = true;
      if (this.id) {
        TaskService.update(this.id, this.model).then(res => {
          this.$emit('saved', res);
        }).finally(() => {
          this.submitting = false
        })
      } else {
        TaskService.create(this.model).then(res => {
          this.$emit('saved', res);
        }).finally(() => {
          this.submitting = false
        })
      }
    }
  }
})
