
import { defineComponent, onMounted, ref, watch } from "vue";
import InsuredIService from "@/core/services/InsuredIService";
import Row from "@/components/base/common/Row.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import store from "@/store";
import InsuredData from "@/views/insured/InsuredData.vue";
import SoNomadInsuredData from "@/views/insured/SoNomadInsuredData.vue";

export default defineComponent({
  name: "InsuredPanel",
  components: {SoNomadInsuredData, InsuredData, DateTimeFormat, Row},
  props: {
    id: {type: String}
  },
  setup(props) {
    const data = ref<any>({})
    const loadData = async (id) => {
      return await InsuredIService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data
    }
  }
})
