import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-body pt-3 pb-3" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReserveLine = _resolveComponent("ReserveLine")!

  return (_ctx.data?.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.data.waived  ? 'Reserve waived':'Reserve Updated'), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ReserveLine, {
            lines: _ctx.data.lines
          }, null, 8, ["lines"]),
          _createElementVNode("div", {
            innerHTML: _ctx.data.comment
          }, null, 8, _hoisted_5)
        ])
      ]))
    : _createCommentVNode("", true)
}