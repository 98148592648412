import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = { class: "card-title" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Row = _resolveComponent("Row")!

  return (_ctx.data.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.data.subject), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Row, {
              row: [{name:'Date' ,value: _ctx.data.dateCreated , type:'DATE'}, {name: 'Recipients', value: _ctx.data?.recipients?.join(',')}]
            }, null, 8, ["row"])
          ]),
          _createElementVNode("div", {
            class: "card-footer",
            innerHTML: _ctx.data.body
          }, null, 8, _hoisted_6)
        ])
      ]))
    : _createCommentVNode("", true)
}