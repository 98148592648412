
import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import Cards from "@/components/base/common/Cards.vue";
import BenefitType from "@/views/product/BenefitType.vue";
import Row from "@/components/base/common/Row.vue";

export default defineComponent({
  name: "ProductBenefitsPanel",
  components: {Row, BenefitType, Cards},
  props: {
    productId: {},
  },
  setup(props) {
    const objectFilter = ref({productId: props.productId});
    const page = computed(() => store.state.ProductBenefitModule.page);
    return  {
      page,
      ...LoadFilterObjects(Actions.LOAD_PRODUCT_BENEFITS, objectFilter.value, []),
    }
  }
})
