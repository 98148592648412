import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "card-header" }
const _hoisted_6 = { class: "card-title" }
const _hoisted_7 = { class: "card-body" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = {
  key: 0,
  class: "col-md-12"
}
const _hoisted_10 = { class: "fw-bolder fs-6" }
const _hoisted_11 = {
  key: 1,
  class: "col-md-6 col-sm-12"
}
const _hoisted_12 = { class: "d-flex flex-wrap py-3 fs-6 fw-bold" }
const _hoisted_13 = {
  class: "flex-grow-1 text-gray-400",
  style: {"white-space":"pre-line"}
}
const _hoisted_14 = { class: "flex text-gray-800" }
const _hoisted_15 = {
  key: 0,
  class: "col-md-12"
}
const _hoisted_16 = {
  style: {"white-space":"pre-line"},
  class: "fw-bolder fs-6"
}
const _hoisted_17 = {
  key: 1,
  class: "col-md-6 col-sm-12"
}
const _hoisted_18 = { class: "d-flex flex-wrap py-3 fs-6 fw-bold" }
const _hoisted_19 = {
  class: "flex-grow-1 text-gray-400",
  style: {"white-space":"pre-line"}
}
const _hoisted_20 = { class: "flex text-gray-800" }
const _hoisted_21 = { class: "card-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateTimeFormat = _resolveComponent("DateTimeFormat")!
  const _component_FormSecureLink = _resolveComponent("FormSecureLink")!

  return (_ctx.data?.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.data.schema.name), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.data.schema.wizards.length > 1)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.data.schema.wizards, (wizard, idx) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: idx }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h3", _hoisted_6, _toDisplayString(wizard.name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(wizard.fields, (field) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: field.name
                        }, [
                          (field.type === 'LABEL_ONLY')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createElementVNode("label", _hoisted_10, _toDisplayString(field.label), 1)
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                _createElementVNode("div", _hoisted_12, [
                                  _createElementVNode("div", _hoisted_13, _toDisplayString(field.label), 1),
                                  _createElementVNode("div", _hoisted_14, [
                                    (field.type==='DATE')
                                      ? (_openBlock(), _createBlock(_component_DateTimeFormat, {
                                          key: 0,
                                          date: Number(_ctx.data.data[field.name])
                                        }, null, 8, ["date"]))
                                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                          _createTextVNode(_toDisplayString(_ctx.data.data[field.name]), 1)
                                        ], 64))
                                  ])
                                ])
                              ]))
                        ], 64))
                      }), 128))
                    ])
                  ])
                ], 64))
              }), 128))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.data.schema.wizards, (wizard, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: idx,
                  class: "row"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(wizard.fields, (field) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: field.name
                    }, [
                      (field.type === 'LABEL_ONLY')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            _createElementVNode("label", _hoisted_16, _toDisplayString(field.label), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            _createElementVNode("div", _hoisted_18, [
                              _createElementVNode("div", _hoisted_19, _toDisplayString(field.label), 1),
                              _createElementVNode("div", _hoisted_20, [
                                (field.type==='DATE')
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      (_ctx.data.data[field.name])
                                        ? (_openBlock(), _createBlock(_component_DateTimeFormat, {
                                            key: 0,
                                            date: Number(_ctx.data.data[field.name])
                                          }, null, 8, ["date"]))
                                        : _createCommentVNode("", true)
                                    ], 64))
                                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                      _createTextVNode(_toDisplayString(_ctx.data.data[field.name]), 1)
                                    ], 64))
                              ])
                            ])
                          ]))
                    ], 64))
                  }), 128))
                ]))
              }), 128))
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createVNode(_component_FormSecureLink, {
            id: _ctx.data.id
          }, null, 8, ["id"])
        ])
      ]))
    : _createCommentVNode("", true)
}