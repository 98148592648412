
import { computed, defineComponent, ref } from "vue";
import { LoadFilterObjects } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import CaseCard from "@/views/case/CaseCard.vue";
import Cards from "@/components/base/common/Cards.vue";

export default defineComponent({
  name: "AllCases",
  components: {Cards, CaseCard},
  props: {
    id: {type: String}
  },
  setup(props) {
    const filterObject = ref({policyId: props.id})
    const data = computed(() => store.state.PolicyModule.cases)
    return {
      ...LoadFilterObjects(Actions.LOAD_POLICY_CASES, filterObject.value, ['insured', 'client', 'policy']),
      data,
      filterObject,
    }
  },
  watch: {
    id(cb) {
      this.filterObject.policyId = cb
      this.updateFilterObject(this.filterObject)
    }
  }
})
