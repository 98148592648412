
import { defineComponent, onMounted, ref, watch } from "vue";
import ProductService from "@/core/services/ProductService";
import Row from "@/components/base/common/Row.vue";
import store from "@/store";
import ProductDocuments from "@/views/product/ProductDocuments.vue";
import ProductBenefitsPanel from "@/views/product/ProductBenefitsPanel.vue";

export default defineComponent({
  name: "ProductPanel",
  components: {ProductBenefitsPanel, ProductDocuments,  Row},
  props: {
    id: {type: String}
  },
  setup(props) {
    const data = ref<any>({})
    const loadData = async (id) => {
      return await ProductService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data
    }
  }
})
