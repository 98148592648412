
import {defineComponent, onMounted, ref, watch} from "vue";
import ProviderService from "../../core/services/ProviderService";
import store from "../../store";
import QuickAction from "../../components/base/action/QuickAction.vue";
import Row from "@/components/base/common/Row.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Notes from "@/views/note/Notes.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import FormNote from "@/views/activity/FormNote.vue";
import Timelines from "@/views/timeline/Timelines.vue";
import Map from "@/views/provider/Map.vue";

export default defineComponent({
  name: "CaseProvider",
  components: {Map, Timelines, FormNote, BaseModal, Notes, DateTimeFormat, Row, QuickAction},
  props: {
    id: {type: String},
  },
  setup(props) {
    const note = ref({});
    const data = ref<any>({})
    const loadData = async (id) => {
      return await ProviderService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      loadData,
      data, note
    }

  },
  methods: {
    onSaveNote() {
      const baseModal = this.$refs.noteForm as typeof BaseModal
      baseModal.hideBaseModal()
      const notes = this.$refs.refNotes as typeof Notes
      notes.paginationLoad();
      this.loadData(this.id);
    },
    onComplete() {
      ProviderService.complete(this.id).then(res => {
        this.data = res
        const timeline = this.$refs.timeline as typeof Timelines
        timeline.paginationLoad();
      })
    }
  }
})
