import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-body pt-3 pb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Row = _resolveComponent("Row")!

  return (_ctx.data?.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.data.name), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Row, {
            row: [{ name: 'Account#', value: _ctx.data.accNum}, {name: 'Medical Record#', value: _ctx.data.mrNum}]
          }, null, 8, ["row"]),
          _createVNode(_component_Row, { row: [{ name: 'Address', slot:true, key:'address'}] }, {
            address: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data?.address?.fullAddress), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Row, {
            row: [{name:'From Date', type:'DATE', value: _ctx.data.fromDt}, {name:'To Date', type:'DATE', value: _ctx.data.toDt}]
          }, null, 8, ["row"]),
          _createVNode(_component_Row, { row: [{name:'Diag1', slot:true, key: 'diag1'}, {name:'Diag2', slot:true, key: 'diag2'}] }, {
            diag1: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.data?.diag1?.code), 1)
            ]),
            diag2: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.data?.diag2?.code), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_Row, { row: [{name:'Diag3', slot:true, key: 'diag3'}, {name:'Diag4', slot:true, key: 'diag4'}] }, {
            diag3: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.data?.diag3?.code), 1)
            ]),
            diag4: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.data?.diag4?.code), 1)
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}