
import {defineComponent, onMounted, ref, watch} from 'vue'
import store from "@/store";
import ReminderService from "@/core/services/ReminderService";
import QuickAction from "@/components/base/action/QuickAction.vue";
import {LoadPanel} from "@/core/composite/composite";
import Audits from "@/views/audit/Audits.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import Row from "@/components/base/common/Row.vue";
import TaskEntity from "@/views/task/TaskEntity.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import CompleteReminderForm from "@/views/reminder/CompleteReminderForm.vue";

export default defineComponent({
  name: "ReminderPanel",
  components: {BaseModal, CompleteReminderForm, TaskEntity, Row, Avatar, Audits, QuickAction},
  props: {
    id: {}
  },
  setup(props) {
    const data = ref<any>({})
    const load = async (id) => {
      return await ReminderService.get(id);
    }
    onMounted(async () => {
      data.value = await load(props.id)
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await load(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    const state = ref({id: ''})
    return {
      data,
      ...LoadPanel(),
      load,
      state,
    }
  },
  methods: {
    onComplete(id) {
      this.state.id = id
      const modal = this.$refs.reminderCompleteForm as typeof BaseModal;
      modal.showBaseModal();
    },
    onCompleted(res) {
      this.data = this.load(res.id)
      const modal = this.$refs.reminderCompleteForm as typeof BaseModal;
      modal.hideBaseModal();
      store.commit('REMINDER_COMPLETE', res.id);
    },
  }
})
