import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-body pt-2" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.selectedDoc.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.selectedDoc.name), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            style: {"width":"100%"},
            innerHTML: _ctx.selectedDoc.body,
            id: "editable-show"
          }, null, 8, _hoisted_5)
        ])
      ]))
    : _createCommentVNode("", true)
}