
import {defineComponent} from "vue";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "TaskEntity",
  props : {
    task: {}
  },
  setup() {
    return {
      ...LoadPanel(),
    }
  }
})
