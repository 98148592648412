
import {defineComponent, ref} from 'vue'
import InsuredIService from "@/core/services/InsuredIService";
import Row from "@/components/base/common/Row.vue";

export default defineComponent({
  name: "SoNomadInsuredData",
  components: {Row},
  props: {
    id: {}
  },
  setup(props) {
    const client = ref({});
    const load = (id) => {
      InsuredIService.getInsuredData(id).then(res => {
        client.value = res;
      })
    }
    load(props.id);
    return {
      client
    }
  },
  methods:{
    buildAddons(items) {
      if (items.length > 0) {
        let results = "";

        items.forEach(i=>{
          results += `${i.name}:${i.coverage},`
        })
        return  results;
      }
      return  ""
    }
  }

})
