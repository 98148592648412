import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    "data-bs-toggle": "tooltip",
    "data-bs-trigger": "hover",
    title: _ctx.desc,
    class: _normalizeClass([{'text-danger':!_ctx.isValid}, "cursor-pointer fw-bold"])
  }, _toDisplayString(_ctx.code), 11, _hoisted_1))
}