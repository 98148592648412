
import {defineComponent, ref, watch} from 'vue'

export default defineComponent({
  name: "SanctionDecisionStatus",
  props: {
    decision: {}
  },
  setup(props) {
    const data = ref({name: '', clazz: ''})
    const build = (status) => {
      switch (status) {
        case 'NEGATIVE':
          data.value = {name: 'Negative', clazz: 'primary'}
          break
        case 'POSITIVE':
          data.value = {name: 'Positive', clazz: 'warning'}
          break
        default:
          data.value =  {name: '', clazz: ''}
      }
    }
    build(props.decision)
    watch(() => props.decision, (cb) => {
      build(cb)
    })
    return {
      data
    }
  }
})
