
import {defineComponent, ref, watch} from "vue";
import ActionTemplateService from "@/core/services/ActionTemplateService";
import PageService from "@/core/services/PageService";

export default defineComponent({
  name: "DocPage",
  props: {
    code: {type: String, required: true},
  },
  setup(props) {
    const selectedDoc = ref({})
    const load = (code) => {
      ActionTemplateService.getByCode(code).then(res => {
        if (res.docKey) {
          PageService.getByKey(res.docKey).then(res => {
            selectedDoc.value = res;
          })
        } else {
          selectedDoc.value = {}
        }
      })
    }
    load(props.code)
    watch(() => props.code, cb => {
      load(cb)
    })
    return {
      selectedDoc
    }
  }
})
