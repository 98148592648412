
import { computed, defineComponent, ref } from "vue";
import { LoadFilterObjects, LoadPanel } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import store from "../../store";
import Pagination from "@/components/base/paging/Pagination.vue";
import DocumentIcon from "@/views/document/DocumentIcon.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import DocumentService from "@/core/services/DocumentService";
import Swal from "sweetalert2";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";

export default defineComponent({
  name: "ProductDocuments",
  components: {EntityLoading, Avatar, QuickAction, DateTimeFormat, DocumentIcon, Pagination},
  props: {
    id: {required: true, type: String}
  },
  setup(props) {
    const filterObject = ref({productId: props.id})
    const page = computed(() => store.state.DocumentModule.allPage);
    return {
      filterObject,
      ...LoadFilterObjects(Actions.LOAD_ALL_DOCUMENTS, filterObject.value, ['createdBy']),
      page,
      ...LoadPanel()
    }
  },
  watch: {
    id(cb) {
      this.filterObject.productId = cb;
      this.updateFilterObject(this.filterObject)
    }
  },
  methods: {
    onDownloadPdf(id) {
      Swal.showLoading()
      DocumentService.download(id).then(res => {
        let fileName = res.headers["content-disposition"].split("filename=")[1];
        let blob = new Blob(
          [res.data]
        );
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        Swal.close()
      })
    }
  }
})
