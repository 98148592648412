
import {defineComponent, onMounted, ref, watch} from "vue";
import Row from "@/components/base/common/Row.vue";
import InsuredType from "@/components/base/common/InsuredType.vue";
import PolicyStatus from "@/views/policy/PolicyStatus.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import PolicyService from "@/core/services/PolicyService";
import AllCases from "@/views/policy/case/AllCases.vue";
import store from "@/store";
import TagEntities from "@/views/tag/TagEntities.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import InsuredStatus from "@/views/insured/InsuredStatus.vue";
import PolicyData from "@/views/policy/PolicyData.vue";

export default defineComponent({
  name: "PolicyPanel",
  components: {
    InsuredStatus,
    EntityLoading,
    PolicyData,
    BaseModal, TagEntities, AllCases, DateTimeFormat, PolicyStatus, InsuredType, Row},
  props: {
    id: {type: String}
  },
  setup(props) {
    const data = ref<any>({})
    const loadData = async (id) => {
      return await PolicyService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    const pdfUrl = ref('')
    const loading = ref(true);
    return {
      pdfUrl,
      loading,
      data
    }
  },
  methods: {
    getConfirm() {
      const modal = this.$refs.policyCoverageConfirmRef as typeof BaseModal;
      modal.showBaseModal();
      this.loading = true
      PolicyService.getCoverageConfirm(this.data.id).then(res => {
        const pdfBlob = new Blob([res.data], {type: 'application/pdf'});
        this.pdfUrl = URL.createObjectURL(pdfBlob);
      }).finally(() => {
        this.loading = false;
      })
    },
    showSoNomadData() {
      const modal = this.$refs.soNomadDataRef as typeof BaseModal;
      modal.showBaseModal();
    }
  }
})
