
import {defineComponent, onMounted, ref, watch} from "vue";
import Row from "@/components/base/common/Row.vue";
import CaseStatus from "@/views/case/CaseStatus.vue";
import CaseService from "@/core/services/CaseService";
import store from "@/store";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import PolicyService from "@/core/services/PolicyService";
import PolicyData from "@/views/policy/PolicyData.vue";
import PolicyStatus from "@/views/policy/PolicyStatus.vue";
import Warnings from "@/views/note/Warnings.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import Timelines from "@/views/timeline/Timelines.vue";
import InsuredData from "@/views/insured/InsuredData.vue";
import TagEntities from "@/views/tag/TagEntities.vue";

export default defineComponent({
  name: "CasePanel",
  components: {
    TagEntities,
    InsuredData,
    Timelines, CurrencyFormat, Avatar, Warnings, PolicyStatus, PolicyData, DateTimeFormat, CaseStatus, Row},
  props: {
    id: {type: String}
  },
  setup(props) {
    const data = ref<any>({})
    const policyData = ref<any>({});
    const loadData = async (id) => {
      return await CaseService.get(id);
    }

    const loadPolicyData = async (id) => {
      return await PolicyService.get(id);
    }

    onMounted(async () => {
      data.value = await loadData(props.id)
      policyData.value = await loadPolicyData(data.value.policy.id);
      store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb);
      policyData.value = await loadPolicyData(data.value.policy.id);
      store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data,
      policyData,
    }
  }
})
