
import {computed, defineComponent} from "vue";
import {useI18n} from "vue-i18n/index";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {Actions} from "@/store/enums/StoreEnums";
import CookieService from "@/core/services/CookieService";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import {COOKIE_KEY} from "@/core/config/Constant";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    const countries = {
      en: {
        flag: "/media/flags/united-states.svg",
        name: "English",
      },
      es: {
        flag: "/media/flags/spain.svg",
        name: "Spanish",
      },
      de: {
        flag: "/media/flags/germany.svg",
        name: "German",
      },
      ja: {
        flag: "/edia/flags/japan.svg",
        name: "Japanese",
      },
      fr: {
        flag: "/media/flags/france.svg",
        name: "French",
      },
    };

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({name: "sign-in"}));
    };

    const isImpersonal = computed(() => store.state.UserModule.isImpersonal)
    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLanguageLocale = computed(() => {
      return countries[i18n.locale.value];
    });


    const user = computed(() => store.getters.currentUser)

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLanguageLocale,
      countries,
      user,
      store,
      isImpersonal
    };
  },
  methods: {
    onAddTask() {
        this.store.commit('SET_GENERIC_MODAL', true);
    },
    switchBack() {
      const token = CookieService.get('TR_TOKEN_ID_OLD')
      JwtService.saveToken(token);
      ApiService.setHeader();
      CookieService.save(COOKIE_KEY.IMPERSONAL, false)
      this.store.dispatch(Actions.LOAD_PROFILE)
      this.store.commit('SET_IMPERSONAL', false)
      window.location.reload()
    }
  }
});
