
import {defineComponent, ref, watch} from 'vue'
import UserService from "@/core/services/UserService";
import {IFilter} from "@/core/entity/IFilter";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "RecentLogin",
  components: {DateTimeFormat},
  props: {
    userId: {}
  },
  setup(props) {
    const loading = ref(true);
    const loginPage = ref({});
    const filter = ref<IFilter>({
      paginationPage: 1,
      paginationSize: 5,
      key: {},
    })
    const loadData = (userId) => {
      loading.value = true;
      UserService.loginHistory(userId, filter.value).then(res => {
        loginPage.value = res;
      }).finally(() => {
        loading.value = false
      })
    }

    loadData(props.userId)
    watch(() => props.userId, cb => {
      loadData(cb)
    })
    return {
      loading,
      loginPage,
      filter,
    }

  }
})
