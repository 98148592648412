
import {defineComponent, onMounted, ref, watch} from "vue";
import PaymentService from "@/core/services/PaymentService";
import store from "@/store";
import {LoadPanel} from "@/core/composite/composite";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import Row from "@/components/base/common/Row.vue";
import PaymentTypeLabel from "@/views/payment/PaymentTypeLabel.vue";
import PaymentStatus from "@/views/payment/PaymentStatusLabel.vue";

export default defineComponent({
  name: "PaymentGroupPanel",
  components: { PaymentStatus, PaymentTypeLabel, Row, CurrencyFormat},
  props: {
    id: {type: String}
  },
  setup(props) {
    const refId = ref(props.id);
    const data = ref<any>({});
    const datas = ref<any>([]);
    const loadData = async (id) => {
      return await PaymentService.getGroup(id);
    }
    const loadGroup = async (id) => {
      return await PaymentService.get(id);
    }

    onMounted(async () => {
      datas.value = await loadData(refId.value);
      data.value = await loadGroup(refId.value);
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      datas.value = await loadData(cb)
      data.value = await loadGroup(refId.value);
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data,
      datas,
      ...LoadPanel(),
    }
  }
})
