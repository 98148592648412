
import {defineComponent, onMounted, ref, watch} from "vue";
import store from "@/store";
import PageService from "@/core/services/PageService";

export default defineComponent({
  name: "DocPanel",
  props: {
    id: {type: String}
  },
  setup(props) {
    const data = ref<any>({})
    const loadData = async (id) => {
      return await PageService.getByKey(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data
    }
  }
})
