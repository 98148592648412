
import { computed, defineComponent, ref } from "vue";
import Avatar from "@/components/base/common/Avatar.vue";
import AuditAction from "@/views/audit/AuditAction.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Pagination from "@/components/base/paging/Pagination.vue";
import { LoadFilterObjects } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import { onUpdated } from "@vue/runtime-core";
import store from "@/store";

export default defineComponent( {
  name: "AuditPanel",
  components: {Pagination, DateTimeFormat, AuditAction, Avatar},
  props: {
    id:{type: Object}
  },
  setup(props) {
    const filterObject = ref({objectType: props.id?.type, objectId: props.id?.id})
    onUpdated(()=>{
      store.commit('SET_PANEL_LOADING', false)
    })
    const auditPage = computed(() => store.state.AuditModule.auditPage)
    return {
      ...LoadFilterObjects(Actions.LOAD_AUDITS, filterObject.value, []),
      auditPage
    }
  }
})
