
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "PaymentStatus",
  props: {
    status: {type: String, required: true}
  },
  setup(props) {
    const data = ref({name: '', clazz: ''})
    const build = (status) => {
      switch (status) {
        case 'PENDING':
          data.value = {name: 'Pending', clazz: 'primary'}
          break
        case 'PAYABLE':
          data.value = {name: 'Payable', clazz: 'info'}
          break
        case 'PAID':
          data.value = {name: 'Paid', clazz: 'success '}
          break
        case 'VOIDED':
          data.value = {name: 'Voided', clazz: 'danger'}
          break
      }
    }
    build(props.status)
    watch(() => props.status, (cb) => {
      build(cb)
    })
    return {
      data
    }
  }
})
