
import {defineComponent, onMounted, ref, watch} from 'vue'
import ServiceService from "@/core/services/ServiceService";
import store from "@/store";
import Row from "@/components/base/common/Row.vue";
import ServiceValue from "@/views/service/ServiceValue.vue";

export default defineComponent({
  name: "ServicePanel",
  components: {ServiceValue, Row},
  props: {
    id: {type: String,}
  },
  setup(props) {
    const refId = ref(props.id);
    const data = ref<any>({});
    const loadData = async (id) => {
      return await ServiceService.get(id);
    }

    onMounted(async () => {
      data.value = await loadData(refId.value);
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data,
    }
  }
})
