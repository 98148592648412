
import {defineComponent, reactive, ref} from "vue";
import TemplateService from "@/core/services/TemplateService";

export default defineComponent({
  name: "TemplateListSelect",
  props: {
    clazz: {type: String, default: () => 'form-control form-control-solid form-control-lg fw-bold'},
    showCompany: {type: Boolean, default: () => true},
    multiple: {type: Boolean, default: () => false},
    clientId: {type: String, default: () => ''},
    type: {type: String, required: true},
  },
  setup(props) {

    const options = ref<any>([])
    const loading = ref(false);
    const state = reactive<any>({
      show: false,
      selectObjects: []
    })
    const loadOptions = (type, clientId) => {
      loading.value = true
      TemplateService.templateList(type, clientId).then(res => {
        options.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }
    loadOptions(props.type, props.clientId);
    return {
      state,
      loading,
      options,
    }
  },
  emits: ['update:modelValue', 'onSelect'],
  methods: {
    onClose() {
      this.state.show = false
    },
    remove(item) {
      this.state.selectObjects = this.state.selectObjects.filter(val => val !== item)
    },
    clear() {
      this.state.selectObjects = [];
    },
    onSelect(item) {
      if (this.state.selectObjects.includes(item)) {
        return
      } else {
        if (this.multiple) {
          this.state.selectObjects.push(item)
        } else {
          this.state.selectObjects = [item]
        }
      }
      this.$emit('onSelect', item)
    }
  }
})
