
import { defineComponent, onMounted, ref, watch } from "vue";
import store from "@/store";
import InvoiceService from "@/core/services/InvoiceService";
import Row from "@/components/base/common/Row.vue";
import { LoadPanel } from "@/core/composite/composite";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import LinesHCFA from "@/views/invoice/LinesHCFA.vue";
import LinesUB04 from "@/views/invoice/LinesUB04.vue";
import Timelines from "@/views/timeline/Timelines.vue";
import StatementName from "@/views/invoice/StatementName.vue";
import TagEntities from "@/views/tag/TagEntities.vue";

export default defineComponent({
  name: "InvoicePanel",
  components: {TagEntities, StatementName, Timelines, LinesUB04, LinesHCFA, CurrencyFormat, Row},
  props: {
    id: {type: String}
  },
  setup(props){
    const data = ref<any>({})
    const loadData = async (id) => {
      return await InvoiceService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data,
      ...LoadPanel(),
    }
  }
})
