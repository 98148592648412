
import {defineComponent, ref, watch} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {CaseSelectFilter, ClaimSelectFilter, InvoiceSelectFilter} from "@/core/composite/composite";
import BaseMultiSelect from "@/components/base/select/BaseMultiSelect.vue";
import Froala from "@/components/base/editor/Froala.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import ProcessService from "@/core/services/ProcessService";
import {Field} from "vee-validate";

export default defineComponent({
  name: "GenericProcessForm",
  components: {UserListSelect, FormErrorMessage, Froala, BaseMultiSelect, BaseRadio, BaseForm, Field},
  props: {
    process: {type: Object},
  },
  emits: ['saved'],
  setup(props) {
    const model = ref<any>(props.process);
    watch(() => props.process, cb => {
      model.value = cb;
    })
    const entity = ref('');
    const submitting = ref(false);
    return {
      model,
      entity,
      ...CaseSelectFilter({clientId: ''}, false),
      ...ClaimSelectFilter({clientId: ''}, false),
      ...InvoiceSelectFilter({clientId: ''}, false),
      submitting,
    }
  },
  methods: {

    onSearchCase(event) {
      this.caseFilter.number = event
      this.loadCaseSelectFilter();
    },
    onSearchClaim(event) {
      this.claimFilter.number = event;
      this.loadClaimSelectFilter();
    },
    onSearchInvoice(event) {
      this.invoiceFilter.number = event;
      this.loadInvoiceSelectFilter();
    },
    onSubmit() {
      this.model.objectTypeRequest = this.entity
      this.submitting = true;
      ProcessService.create<any>(this.model).then(res => {
        this.$emit('saved', res)
        this.$router.push({name: 'processDetail', params: {id: res.id}})
      }).finally(() => {
        this.submitting = false;
        this.entity = '';
        this.model.objectTypeReques = ''
        this.model.objectIdRequest = ''
      })
    }
  }

})
