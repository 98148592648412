
import MenuItem from "@/layout/aside/tabs/MenuItem.vue";
import { computed, defineComponent } from "vue";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import PageService from "@/core/services/PageService";
import ContentMappingService from "@/core/services/ContentMappingService";

export default defineComponent({
  name: "HelpMenu",
  components: {MenuItem},
  setup() {
    const menuData = computed(() => store.state.PageModule.menuData)
    const loadMapping = () => {
      ContentMappingService.getType('HELP_CENTER').then(res => {
        const ids: any = [];
        res.forEach(item => {
          ids.push(item.key.trim())
        })
        if (ids.length > 0) {
          PageService.menu(ids[0]).then(res => {
            store.commit(Mutations.SET_HELP_MENU, res)
          })
        }
      })
    }
    loadMapping();
    return {
      menuData,
    }
  },
  methods: {
    onClickItem(item) {
      if (item.type === 'COLLECTION' || item.type === 'FOLDER') {
        if (item.active == false) {
          PageService.findAll<any>({key: {parentId: item.id, status: 'PUBLISHED', s: 'sort:order:asc'}}).then(res => {
            store.commit(Mutations.SET_PAGE_ITEMS, {data: res.data, item: item})
          })
        }
        if (item.type === 'COLLECTION') {
          this.$router.push("/help/doc/collection/" + item.id);
        } else {
          this.$router.push("/help/doc/folder/" + item.id);
        }
      } else {
        this.$router.push("/help/doc/" + item.id);
      }
      store.commit(Mutations.SET_ACTIVE_PAGE, item);
    }
  }
})
