
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "InsuredType",
  props: {
    type:{required:true}
  },
  setup(props) {
    const data = ref({name: '', clazz: ''})
    const build = (type) => {
      switch (type) {
        case 'INSURED':
          data.value = {name: 'Insured', clazz: 'success'}
          break
        case 'PRIMARY':
          data.value = {name: 'Primary', clazz: 'secondary'}
          break
        case 'SPOUSE':
          data.value = {name: 'Spouse', clazz: 'danger'}
          break
        case 'CHILD':
          data.value = {name: 'Child', clazz: 'warning'}
          break
        case 'MEMBER':
          data.value = {name: 'Member', clazz: 'warning'}
          break
      }
    }
    build(props.type)
    watch(() => props.type, (cb) => {
      build(cb)
    })
    return {
      data
    }
  }

})
