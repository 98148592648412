
import {defineComponent} from "vue";
import CaseStatus from "@/views/case/CaseStatus.vue";
import ClaimDecisionStatus from "@/views/claim/adjudication/ClaimDecisionStatus.vue";
import ClaimDocStatus from "@/views/claim/adjudication/ClaimDocStatus.vue";

export default defineComponent({
  name: "ClaimStatusMsg",
  components: {ClaimDocStatus, ClaimDecisionStatus, CaseStatus},
  props: {
    claim: {}
  },
  setup() {

  }
})
