import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "d-flex flex-wrap py-3 fs-6 fw-bold" }
const _hoisted_3 = { class: "flex-grow-1 text-gray-400" }
const _hoisted_4 = { class: "flex text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, idx) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-6",
        key: idx
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(Object.keys(item)[0]), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(Object.values(item)[0]), 1)
        ])
      ]))
    }), 128))
  ]))
}