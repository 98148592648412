
import {defineComponent} from 'vue'
import store from "@/store";

export default defineComponent({
  name: "QuickMenu",
  methods: {
    onAddTask() {
      store.commit('SET_GENERIC_MODAL', {isShow: true, objectType: 'TASK'});
    },
    onAddReminder() {
      store.commit('SET_GENERIC_MODAL', {isShow: true, objectType: 'REMINDER'});
    },

    onAddProcess(type) {
      store.dispatch('SHOW_PROCESS_MODAL', {type: type})
    }
  }

})
