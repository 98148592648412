
import { defineComponent, onMounted, ref, watch } from "vue";
import ProviderService from "@/core/services/ProviderService";
import Row from "@/components/base/common/Row.vue";
import store from "@/store";

export default defineComponent({
  name: "ProviderPanel",
  components: {Row},
  props: {
    id: {type: String},
  },
  setup(props) {
    const data = ref<any>({})
    const loadData = async (id) => {
      return await ProviderService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data
    }
  }
})
