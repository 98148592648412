
import {defineComponent, onMounted} from 'vue'
import {Loader} from "@googlemaps/js-api-loader";

export default defineComponent({
  name: "Map",
  components: {},
  props: {data: {type: Object, required: true}},
  setup(props) {
    console.log(props.data.lat)
    const loader = new Loader({
      apiKey: "AIzaSyDjfqpMFaMOaiEzg6cIVSgXswsPBKkXFyM",
      version: "weekly",
    });

    onMounted(async () => {

      const {Map} = await loader.importLibrary("maps");
      const {AdvancedMarkerElement} = await loader.importLibrary("marker");

      const doc = document?.getElementById("map-provider");
      // The map, centered at Uluru'
      let map;
      if (doc) {
        map = new Map(doc, {
          zoom: 10,
          center: {lat: Number(props.data.lat), lng: Number(props.data.lng)},
          mapId: 'DEMO_MAP_ID'
        });

        // The marker, positioned at Uluru
        new AdvancedMarkerElement({
          map: map,
          position: {lat: Number(props.data.lat), lng: Number(props.data.lng)},
        });
      }
    })


  }
})
