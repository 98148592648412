
import {defineComponent, ref} from 'vue'
import Froala from "@/components/base/editor/Froala.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import ReminderService from "@/core/services/ReminderService";

export default defineComponent({
  name: "CompleteReminderForm",
  components: {BaseForm, Froala},
  props: {
    id: {type: String, required: true}
  },
  emits: ['completed'],
  setup() {
    const model = ref({completeNote: ''});
    const submitting = ref(false);
    return {
      submitting,
      model,
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      ReminderService.complete(this.id, this.model).then(res => {
        this.$emit('completed', res);
      }).finally(() => {
        this.submitting = false
        this.model.completeNote = ''
      })
    }
  }
})
