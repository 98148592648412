
import {defineComponent, onMounted, ref, watch} from 'vue'
import PolicyRemoteSearchService from "@/core/services/PolicyRemoteSearchService";
import store from "@/store";
import Row from "@/components/base/common/Row.vue";
import Swal from "sweetalert2";
import {useToast} from "vue-toast-notification";

export default defineComponent({
  name: "PolicyRemotePanel",
  components: {Row},
  props: {
    id: {type: Object}
  },
  setup(props) {
    const data = ref<any>({})
    const loadData = async (id: any) => {
      return await PolicyRemoteSearchService.getCovered(id.policyCode, id.certificate, id.coveredLifeId);
    }

    onMounted(async () => {
      data.value = await loadData(props.id)
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data
    }
  },
  methods: {
    onImport() {
      Swal.showLoading()
      PolicyRemoteSearchService.createPolicy(this.id?.policyCode, this.id?.certificate, this.id?.coveredLifeId).then(res => {
        this.$router.push("/policies/" + res.id)
        useToast().success('Save successfully', {position: 'top-right'})
      }).finally(() => {
        Swal.close()
      })
    }
  }
})
