
import {computed, defineComponent, ref} from "vue";
import KTMenu from "@/layout/aside/Menu.vue";
import {asideSecondaryDisplay, minimizationEnabled, minimizedAsideSecondary} from "@/core/helpers/config";
import {getIllustrationsPath} from "@/core/helpers/assets";
import {
  AdminMenu, Assistance,
  CaseManagement,
  CaseMenu,
  Claims, CustomerMenu,
  Finance,
  PolicyMenu,
  Team,
  ToolMenu
} from "@/core/config/MainMenuConfig";
import HelpMenu from "@/layout/aside/HelpMenu.vue";
import ReportMenu from "@/layout/aside/ReportMenu.vue";
import CookieService from "@/core/services/CookieService";
import store from "@/store";

export default defineComponent({
  name: "kt-aside-secondary",
  components: {
    ReportMenu,
    HelpMenu,
    KTMenu,
  },
  setup() {
    const nodeENV = process.env.NODE_ENV
    const env = process.env.VUE_APP_ENV
    const href = ref('policy_menu');
    const nav = CookieService.get('NAV');
    const user = computed(() => store.getters.currentUser);
    if (nav) {
      href.value = nav;
    }
    return {
      minimizedAsideSecondary,
      asideSecondaryDisplay,
      minimizationEnabled,
      PolicyMenu,
      Finance,
      Team,
      CaseMenu,
      CaseManagement,
      CustomerMenu,
      AdminMenu,
      ToolMenu,
      Assistance,
      Claims,
      getIllustrationsPath,
      env,
      nodeENV,
      href,
      user,
    };
  },
});
