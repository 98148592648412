
import {defineComponent, onMounted, ref, watch} from 'vue'
import store from "@/store";
import EmailService from "@/core/services/EmailService";
import Row from "@/components/base/common/Row.vue";

export default defineComponent({
  name: "EmailPanel",
  components: {Row},
  props: {
    id: {}
  },
  setup(props) {
    const data = ref<any>({})
    const load = async (id) => {
      return await EmailService.get(id);
    }
    onMounted(async () => {
      data.value = await load(props.id)
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await load(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data,
    }
  }
})
