
import {defineComponent, ref, watch} from 'vue'
import DocumentService from "@/core/services/DocumentService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";

export default defineComponent({
  name: "DocumentTranscribe",
  components: {EntityLoading},

  props: {
    id: {}
  },
  setup(props) {
    const loading = ref(true);
    const data = ref({});
    const loadData = (id) => {
      loading.value = true
      if (id) {
        DocumentService.getUrl(id).then(res => {
          data.value = res;
        }).finally(() => {
          loading.value = false
        })
      } else {
        loading.value = false;
        data.value = {}
      }
    }
    loadData(props.id)
    watch(() => props.id, cb => {
      loadData(cb);
    })
    return {
      data,
      loading,
    }
  },
})
