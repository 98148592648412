
import {defineComponent, onMounted, ref, watch} from "vue";
import store from "@/store";
import TaskService from "@/core/services/TaskService";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import Row from "@/components/base/common/Row.vue";
import Swal from "sweetalert2";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import FormTask from "@/views/task/FormTask.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import Froala from "@/components/base/editor/Froala.vue";
import Audits from "@/views/audit/Audits.vue";
import Panel from "@/components/base/panel/Panel.vue";
import ActionViewPanel from "@/views/actionView/ActionViewPanel.vue";
import TaskEntity from "@/views/task/TaskEntity.vue";
import {Field} from "vee-validate";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";

export default defineComponent({
  name: "TaskPanel",
  components: {
    FormErrorMessage,
    TaskEntity,
    Field,
    ActionViewPanel,
    Panel, Audits, Froala, BaseForm, FormTask, BaseModal, Row, Avatar, QuickAction
  },
  props: {
    id: {type: String}
  },
  setup(props) {
    const actionLogShow = ref(false);
    const taskReq = ref({
      name: '',
      status: 'NOT_SET',
      description: '',
      dueDate: new Date().getTime(),
      cazeReqId: '',
      claimReqId: '',
      invoiceReqId: '',
      assigneeReqId: '',
      teamReqId: '',
      assignType: 'USER'
    });
    const submitting = ref(false)
    const complete = ref({duration: 0, completedNote: ''})
    const data = ref<any>({})
    const loadData = async (id) => {
      return await TaskService.get(id);
    }
    onMounted(async () => {
      data.value = await loadData(props.id)
      await store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, async (cb) => {
      data.value = await loadData(cb)
      await store.commit('SET_PANEL_LOADING', false)
    })
    return {
      loadData,
      data,
      taskReq,
      complete,
      submitting,
      actionLogShow
    }
  },
  methods: {
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await TaskService.delete(id).then(() => {
            store.commit('TASK_DELETE', id);
            store.commit('SET_PANEL_STATUS', false)
          })
        }
      })
    },
    onEdit(task) {
      this.taskReq = {
        name: task.name,
        status: task.status,
        description: task.description,
        dueDate: task.dueDate,
        cazeReqId: task.cazeId,
        claimReqId: task.claimId,
        invoiceReqId: task.invoiceId,
        assigneeReqId: task.assignee ? task.assignee.id : '',
        teamReqId: task.team ? task.team.id : '',
        assignType: task.team?.id ? 'TEAM' : 'USER'
      }
      const modal = this.$refs.formTaskRef as typeof BaseModal
      modal.showBaseModal();
    },

    onCompleteTask() {
      this.complete = {duration: 0, completedNote: ''}
      const modal = this.$refs.completeTaskRef as typeof BaseModal
      modal.showBaseModal();
    },

    onSubmitComplete() {
      this.submitting = true
      TaskService.complete(this.data.id, this.complete).then(async () => {
        this.data = await this.loadData(this.data.id)
        const modal = this.$refs.completeTaskRef as typeof BaseModal
        modal.hideBaseModal();
        const audit = this.$refs.auditRef as typeof Audits;
        audit.paginationLoad()

        store.commit('TASK_COMPLETE', this.data.id)

        const actionLogsView = this.$refs.actionLogsView as typeof ActionViewPanel;
        actionLogsView?.paginationLoad()

      }).finally(() => {
        this.submitting = false
      })
    },

    assignToMe(id) {
      TaskService.assignToCurrent(id).then(async () => {
        this.data = await this.loadData(this.data.id)
        const audit = this.$refs.auditRef as typeof Audits;
        audit.paginationLoad()
        const actionLogsView = this.$refs.actionLogsView as typeof ActionViewPanel;
        actionLogsView.paginationLoad()
      })
    },
    async onSaved() {
      const audit = this.$refs.auditRef as typeof Audits;
      audit.paginationLoad()
      this.data = await this.loadData(this.data.id)
      const actionLogsView = this.$refs.actionLogsView as typeof ActionViewPanel;
      actionLogsView.paginationLoad()
    }
  }
})
