
import {computed, defineComponent} from "vue";
import store from "@/store";

export default defineComponent({
  name: "ReportMenu",
  setup() {
    const menu = computed(() => store.state.QuicksightModule.menu);
    return {menu}
  }
})
